import React from 'react';
import PropTypes from 'prop-types';
import styles from './services.module.css';

const Services = () => (
  <section id="services">Services</section>
);

Services.propTypes = {};

Services.defaultProps = {};

export default Services;
