import React from 'react';
import PropTypes from 'prop-types';
import styles from './footer.module.css';

const Footer = () => (
  <section id="footer">Footer</section>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
