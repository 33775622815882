import React from 'react';
import PropTypes from 'prop-types';
import styles from './portfolio.module.css';

const Portfolio = () => (
  <section id="portfolio">Portfolio</section>
);

Portfolio.propTypes = {};

Portfolio.defaultProps = {};

export default Portfolio;
